import React from "react"

import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import SEO from "../components/seo"
import ChatBot from "../components/about/ChatBot"

const TestPage = () => {

  return (
    <InnerLayout>
      <SEO title="Test"/>
      <InnerWrapper>
        <section className="Test outer-padding-x outer-padding-y">
          <ChatBot/>
        </section>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default TestPage
